<template>
  <div
    style="
      height: 100vh;
      width: 100vw;
      position: fixed;
      overflow: hidden;
      left: 0;
      top: 0;
    "
  >
    <div class="map-box" id="map_box">
      <!-- ,'transform':map_transform -->
      <img
        :src="oss + map_obj.img_url"
        draggable="false"
        class="map_img"
        :style="{ zoom: zoom * scaleZoom }"
        id="map"
        @click="clickImg"
      />
      <div
        class="map-icon flex flex-co flex-c"
        :class="isMobile ? '' : 'map-iconPc'"
        v-for="(item, index) in covers"
        :key="index"
        @click="markerTap(index, true)"
        :style="{
          top: item.position.lat_percent * imgHeight * zoom * scaleZoom + 'px',
          left: item.position.lng_percent * imgWidth * zoom * scaleZoom + 'px',
        }"
      >
        <div class="imgSty" v-if="item.position.lng_percent != null">
          <img :src="oss + item.icon" />
        </div>
        <div class="icon_text" v-if="item.position.lng_percent != null">
          {{ item.content }}
        </div>
      </div>
      <div
        class="map-icon"
        :class="isMobile ? '' : 'map-iconPc'"
        v-if="myLocation"
        :style="{
          top: nowLocation.lng_percent * imgHeight * zoom * scaleZoom + 'px',
          left: nowLocation.lat_percent * imgWidth * zoom * scaleZoom + 'px',
        }"
      >
        <div>
          <img src="../../static/img/my_location.png" />
        </div>
        <div class="icon_text" style="color: red">我</div>
      </div>
      <!-- <div class="map-icon" v-for="(item, index) in start_end_icon" :key="index+'_1'" 
        style="transform: translate(0, -66%);"
        :style="{ 'top': (item.lng_percent) + 'px', 'left': (item.lat_percent) + 'px' }">
        <img v-if="item.icon==2" src="../../static/img/blue-map.png" />
        <img v-if="item.icon==1" src="../../static/img/red-map.png" />
        <div class="icon_text">{{item.content}}</div>
      </div> -->
      <div v-html="lines"></div>
    </div>
    <div>
      <div class="recommend" v-if="advertList.length > 0 || scence_advert_list.length > 0">
        <div class="recommend-img" v-if="scence_advert_list.length > 0">
          <van-swipe :circular="true" :autoplay="3000" :interval="5000" :duration="1000" height="100">
            <van-swipe-item v-for="(item,index) in scence_advert_list" :key="index">
              <img style="width:100%" :src="oss + item.banner_img" mode="aspectFill"
                @click="advNavigateTo(item.banner_url)"/>
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="recommend-img" v-else>
          <van-swipe :circular="true" :autoplay="3000" :interval="5000" :duration="1000" height="100">
            <van-swipe-item v-for="(item,index) in advertList" :key="index">
              <img :src="oss + item.banner +'?x-oss-process=style/img_zip'" mode="aspectFill"
                @click="advNavigateTo(item.url)"/>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <div class="flex-ai-c flex-jc-sb adRightList" v-if="adRightList.length > 0" @click="advNavigateTo(adRightList[0].link)">
        <img class="adRightListImg" v-if="adRightList[0].image" :src="oss + adRightList[0].image" mode="aspectFill"/>
        <div class="adRightListText" v-else>{{ adRightList[0].text }}</div>
      </div>
      <div
        class="flex flex-ai-c flex-jc-sb"
        :class="isMobile ? 'bottomIconList' : 'bottomIconListFeng'"
      >
        <!-- <div>
          <div class="locationBgc" :style="myLocation ? 'background-color:#c0e6fd' : 'background-color:#fff'" @click="clickMyLocation()">
            <img class="locationImg" src="../../static/img/icon_map_location.png" />
          </div>
          <div class="typeface" style="margin-top: 7px;">
            我的定位
          </div>
        </div> -->
        <div>
          <div
            :class="isMobile ? 'locationBgc' : 'locationBgcPc'"
            :style="
              is_show_more
                ? 'background-color:#c0e6fd'
                : 'background-color:#fff'
            "
            @click="isShowMore()"
          >
            <img
              class="locationImg"
              src="../../static/img/more-icon.png"
              v-if="!is_show_more"
            />
            <img
              class="locationImg"
              src="../../static/img/more-blue-icon.png"
              v-if="is_show_more"
            />
          </div>
          <div
            :class="isMobile ? 'typeface' : 'typefacePc'"
            style="margin-top: 7px"
            :style="is_show_more ? 'color:#3b8efb' : 'color:#000'"
          >
            {{ is_show_more ? '多分类' : '单分类' }}
          </div>
        </div>
        <div
          class="flex flex-ai-c"
          :class="isMobile ? 'poiList' : 'poiListPc'"
          v-if="categoryPoiList.length > 0"
          :style="!isPackUp ? '' : 'width:60%;'"
        >
          <div class="poiListLeft flex" v-if="isPackUp">
            <div
              class="poiItem flex flex-co flex-c"
              :style="
                poiItem.is_default_display ? 'opacity: 1' : 'opacity: 0.4'
              "
              v-for="(poiItem, poiIndex) in categoryPoiList"
              :key="poiIndex"
              @click="clickPoi(poiItem, poiIndex)"
            >
              <img
                class="poiIcon"
                :class="isMobile ? '' : 'poiIconPc'"
                :src="oss + poiItem.icon"
              />
              <div
                class="typeface poiText"
                :class="isMobile ? 'poiText' : 'poiTextPc'"
              >
                {{ poiItem.name }}
              </div>
            </div>
          </div>
          <div
            class="icon_map_right flex flex_s_c"
            @click="packUp()"
            :style="isMobile ? '' : 'height:90px'"
          >
            <img
              class="icon_map_right_arrow"
              :style="isMobile ? '' : 'width:20px;height:20px'"
              src="../../static/img/icon_map_right_arrow.png"
              v-if="isPackUp"
            />
            <img
              class="icon_map_right_arrow"
              src="../../static/img/icon_map_left_arrow.png"
              v-if="!isPackUp"
            />
          </div>
        </div>
      </div>
      <div class="markerDetail" v-if="markerDetailPop">
        <div class="markerItem">
          <div class="markerItemTop">
            <div class="markerTitle">{{ markerDetail.name }}</div>
            <img
              class="vr_img_icon"
              src="../../static/img/vr.png"
              alt=""
              v-if="markerDetail.vr_img"
              @click="toVr(markerDetail.name)"
            />
            <img
              class="vr_img"
              :src="oss + markerDetail.vr_img"
              alt=""
              v-if="markerDetail.vr_img"
              @click="toVr(markerDetail.name)"
            />
            <div style="margin: 10px 0">
              <div class="flex flex-c beginBtn" @click="startExplaining" v-if="!isExplaining && markerDetail.radio_file">
                <img class="erji" src="../../static/img/erji.png" alt="">
                <span>开始语音讲解</span>
              </div>
              <audio
                v-if="markerDetail.radio_file && isExplaining"
                style="text-align: left"
                :src="oss + markerDetail.radio_file"
                autoplay
                :poster="
                  markerDetail.img_list.length > 0
                    ? oss + markerDetail.img_list[0].file_path
                    : ''
                "
                :name="markerDetail.name"
                :author="markerDetail.another_name"
                :action="audioAction"
                controls
              ></audio>
            </div>
            <!-- <div class="flex flex_s_ev">
              <div class="btn joinGuide" @click="joinGuideBtn => markerDetailPop = false">进入电子导览</div>
            </div> -->
          </div>
          <!-- <xzj-readMore hideLineNum="4" showHeight="100"></xzj-readMore> -->
          <div
            class="distance_text"
            :class="{ 'ellipsis-multi-line': !is_show_distance }"
            v-html="markerDetail.introduce"
          > </div>
          <div
            class="is_show_distance"
            @click="is_show_distance = true"
            v-if="!is_show_distance"
          >
            展示更多
          </div>
          <div
            class="is_show_distance"
            @click="is_show_distance = false"
            v-if="is_show_distance"
          >
            收起
          </div>
          <div class="distance flex">
            <div style="margin-right: 5px"></div>
            <div>游玩适宜时间{{ markerDetail.visited_time }}</div>
          </div>
          <div class="flex markerImg">
            <div
              class="markerImgItem"
              v-for="(markerItem, markerIndex) in markerDetail.img_list"
              :key="markerIndex"
            >
              <img
                class="markerImgItemImg"
                :src="oss + markerItem.file_path"
                @click="preImg(markerDetail.img_list, markerIndex)"
              />
            </div>
          </div>
        </div>
        <!-- <div class="remarkOn">
          <div class="remarkOnTitle">网友点评</div>
          <div class="remarkOnContent">
            <div
              class="remarkOnItem"
              v-for="(reviewItem, reviewIndex) in reviewList"
              :key="reviewIndex"
            >
              <div class="remarkOnItemUser flex flex-jc-sb">
                <div class="flex remarkOnItemLeft">
                  <img class="remarkOnImg" :src="item.user__avatar_url" />
                  <div class="remarkOnName flex flex-jc-sa flex-co">
                    <span>{{ reviewItem.user__nickname }}</span>
                    <span>评分{{ reviewItem.point }}</span>
                  </div>
                </div>
                <div class="remarkOnItemRight">
                  {{ reviewItem.create_time }}
                </div>
              </div>
              <div class="remarkOnText">{{ reviewItem.content }}</div>
              <div class="flex remarkOnList">
                <img
                  class="imgItem"
                  :src="oss + imgItem.file_path"
                  v-for="(imgItem, imgIndex) in reviewItem.img_list"
                  @click="preImg(reviewItem.img_list, markerIndex)"
                  :key="imgIndex"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="operating flex flex_s_ev">
          <div
            class="operatingBtn btnText"
            @click="go_write_comment(markerDetail)"
          >
            我要点评
          </div>
        </div> -->
      </div>
      <div class="markerDetail" v-if="searchPop">
        <div class="searchRegion">
          <div class="search-top flex flex_s_c flex_s_b">
            <div class="search-ctn">
              <div class="search-icon"></div>
              <div class="search-input">
                <input
                  class="input-val"
                  type="text"
                  @input="go_to_search"
                  v-model="search_keyword"
                  placeholder="输入搜索内容"
                />
              </div>
            </div>
            <div class="cancel-search" @click="closeSearchPop">取消</div>
          </div>
          <div v-if="!isSearchResult">
            <!-- 搜索的关键字 -->
            <div class="search-section">
              <div class="title">推荐搜索</div>
              <div class="search-keyword-list flex flex_s_c flex_w">
                <div
                  class="keyword-item"
                  @click="search_for_keyword(r_item.keyword, false)"
                  v-for="(r_item, r_index) in keyWordList"
                  :key="r_index"
                >
                  {{ r_item.keyword }}
                </div>
              </div>
            </div>
            <div class="search-section" v-if="history_keyword_list.length != 0">
              <div class="title">历史搜索</div>
              <div class="delete" @click="del_history_search"></div>
              <div class="search-keyword-list flex flex_s_c flex_w">
                <div
                  class="keyword-item"
                  @click="search_for_keyword(h_item, false)"
                  v-for="(h_item, h_index) in history_keyword_list"
                  :key="h_index"
                >
                  {{ h_item }}
                </div>
              </div>
            </div>
          </div>
          <div class="searchResult" v-if="isSearchResult">
            <div
              class="searchResultItem flex flex_s_b"
              v-for="(searchItem, searchIndex) in searchResultList"
              :key="searchIndex"
            >
              <div
                v-if="searchResultList.length > 0"
                @click="goSearchPoint(searchItem)"
              >
                <div class="searchResultItemLeft flex">
                  <img
                    class="searchResultItemLeftImg"
                    src="../../static/img/icon_gray_location.png"
                    alt=""
                  />
                  <div class="searchResultItemInfo">
                    <div class="searchResultItemInfoName">
                      <span class="name">{{ searchItem.name }}</span>
                      <span class="classTag">{{
                        searchItem.category__name
                      }}</span>
                    </div>
                    <div class="searchResultItemNickname">
                      别称：{{ searchItem.another_name }}
                    </div>
                    <div class="searchResultItemAdress">
                      地址：{{ searchItem.address }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="searchResultItemRight flex flex-c flex-co">
                <img
                  class="searchResultItemRightImg"
                  src="../../static/img/icon_grey_search.png"
                  alt=""
                />
                <span class="searchResultItemRightText">路线</span>
              </div>
            </div>
            <div class="noMore flex flex-c" v-if="searchResultList.length == 0">
              暂无搜索数据
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <div class="flex mapTopItem">
          <!-- .flex-jc-sb -->
          <div class="mapTopItem flex" v-if="!isFenghuanghu">
            <div class="flex flex-c ticketIcon" @click="goTicketPage" v-if="scenic != '9dffefb576fd4405a92f7111b94d9af8'">
              <img class="icon_ticket" src="../../static/img/ticket.png" />
              <div class="typeface">门票</div>
            </div>
            <div
              class="flex flex-c ticketIcon"
              style="width: 75px; margin-left: 8px"
              @click="goConsultationFeedbackPage"
              v-if="!options.is_wm_min === 'weimei'"
            >
              <img class="icon_ticket" src="../../static/img/service.png" />
              <div class="typeface">咨询反馈</div>
            </div>
          </div>
          <!-- <div class="flex flex-c searchBtn" @click="searchBtn">
            <img class="searchIcon" src="../../static/img/icon_grey_search.png" />
            <div class="searchTypeface">搜索</div>
          </div> -->
        </div>
        <div class="flex flex-jc-fe">
          <div
            class="functionalZone"
            :class="isMobile ? 'functionalZone' : 'functionalZonePc'"
          >
            <!-- <div class="functionalZoneItem flex-c flex flex-co" @click="clickExplain">
              <img class="functionalZoneItemIcon" :src="!isShowExplain ? '/static/img/common/icon_grey_broadcast.png' : '/static/img/common/icon_blue_broadcast.png'"></img>
              <div class="typeface typefaceBorder typefaceTop" :class="isShowExplain ? 'selectedFont' : ''">讲解</div>
            </div> -->
            <!-- <div class="functionalZoneItem flex-c flex flex-co" @click="clickMember">
              <img class="functionalZoneItemIcon" v-if="!isShowMember" src="../../static/img/icon_grey_group.png" />
              <img class="functionalZoneItemIcon" v-if="isShowMember" src="../../static/img/icon_blue_group.png" />
              <div class="typeface typefaceBorder typefaceTop" :class="isShowMember ? 'selectedFont' : ''">
                团员
              </div>
            </div> -->
            <!-- <div class="functionalZoneItem flex-c flex flex-co" @click="searchBtn">
              <img class="functionalZoneItemIcon" src="../../static/img/icon_grey_search.png" />
              <div class="typeface typefaceBorder typefaceTop">搜索</div>
            </div> -->
            <div
              class="functionalZoneItem flex-c flex flex-co"
              @click="clickRoute"
            >
              <img
                class="functionalZoneItemIcon"
                v-if="!isShowRouteItem"
                src="../../static/img/icon_grey_flag.png"
              />
              <img
                class="functionalZoneItemIcon"
                v-if="isShowRouteItem"
                src="../../static/img/icon_blue_flag.png"
              />
              <div
                class="typeface typefaceBorder typefaceTop"
                :style="isMobile ? '' : 'font-size:24px'"
                :class="isShowRouteItem ? 'selectedFont' : ''"
              >
                行程
              </div>
            </div>
            <div class="functionalZoneItem flex-c flex flex-co">
              <img
                v-if="scenic != '9dffefb576fd4405a92f7111b94d9af8'"
                class="functionalZoneItemIcon"
                src="../../static/img/north_logo.png"
              />
              <img
              v-if="scenic == '9dffefb576fd4405a92f7111b94d9af8'"
                class="functionalZoneItemIcon"
                src="../../static/img/sourth_logo.jpg"
              />
              <div
                class="typeface typefaceBorder typefaceTop"
                :style="isMobile ? '' : 'font-size:22px'"
              >
                北向
              </div>
            </div>
            <div
              class="functionalZoneItem flex-c flex flex-co"
              @click="goofficiaWeb"
              v-if="isFenghuanghu"
            >
              <img
                class="functionalZoneItemIcon"
                src="../../static/img/offciaWeb.png"
              />
              <div
                class="typeface typefaceBorder typefaceTop"
                :style="isMobile ? '' : 'font-size:22px'"
              >
                官网
              </div>
            </div>
            <div
              class="functionalZoneItem flex-c flex flex-co"
              @click="goVr"
              v-if="isFenghuanghu"
            >
              <img
                class="functionalZoneItemIcon"
                src="../../static/img/quanjing.png"
              />
              <div
                class="typeface typefaceBorder typefaceTop"
                :style="isMobile ? '' : 'font-size:22px'"
              >
                全景
              </div>
            </div>
            <!-- <div class="functionalZoneItem flex-c flex flex-co" @click="clickArea">
              <img class="functionalZoneItemIcon" v-if="!isShowArea" src="../../static/img/icon_gray_location.png" />
              <img class="functionalZoneItemIcon" v-if="isShowArea" src="../../static/img/icon_group_blue_location.png" />
              <div class="typeface typefaceTop" :class="isShowArea ? 'selectedFont' : ''">区域</div>
            </div> -->
          </div>
        </div>
        <!-- 行程 -->
        <div
          class="routeLine flex"
          v-if="isShowRouteItem"
          :style="
            isMobile ? '' : 'top: 130px;height: 25px;font-size: 20px;right:62px'
          "
        >
          <div
            class="routeLineItem"
            @click="clickLine('推荐线路', -1)"
            :style="lineIndex === -1 ? 'color: #138eff;' : ''"
          >
            推荐线路
          </div>
          <div
            class="routeLineItem"
            :style="routeIndex === lineIndex ? 'color: #138eff;' : ''"
            v-for="(
              routeItem, routeIndex
            ) in addressLineList.area_info_item_list"
            :key="routeIndex"
            @click="clickLine(routeItem, routeIndex)"
          >
            {{ routeItem.name ? routeItem.name : '' }}
            <!-- <span v-if="routeItem.name.length>4">...</span> -->
          </div>
        </div>
        <!-- <button class="menberBtn" open-type="share" v-if="isShowMember">共享实时定位</button> -->
      </div>
    </div>
  </div>
</template>
<script>
// import xzjReadMore from '@/components/xzj-readMore/xzj-readMore.vue'
import wx from 'weixin-js-sdk'
import history from '../../static/js/history.js'
import {
  getMapPointList,
  getScenicIndex,
  getWeather,
  delUserRouter,
  getPointDetail,
  getKeywordList,
  getRouterList,
  getPointReview,
  getAddressApi,
  getHandDrawnMap,
} from '@/api/index'
export default {
  name: 'mapImg',
  // components: { xzjReadMore },
  data() {
    return {
      oss: 'https://qian-jing-files.oss-cn-hangzhou.aliyuncs.com', // 正式服
      text_oss: 'https://hz-197201-test.oss-cn-hangzhou.aliyuncs.com', // 测试服
      baseUrl: 'https://q12301.com',
      scenic: null,
      options: {},
      zoom: 0,
      screenHeight: 0,
      screenWidth: 0,
      imgHeight: 0,
      imgWidth: 0,
      changeHeight: 0,
      changeWidth: 0,
      mapLocation: {
        leftTop: {},
        leftBottom: {},
        rightTop: {},
        rightBottom: {},
      },
      location_range: {
        lat: 0,
        lng: 0,
      },
      categoryPoiList: [],
      share_latlng: null,
      advertList: [], // 电商广告栏
      myLocation: false,
      isShowRouteDetails: false,
      user_location: {},
      location: {
        latitude: '',
        longitude: '',
      },
      scenic_latAndlng: {},
      isShowRouteItem: false,
      lineIndex: null,
      lineList: {},
      polylines: [], //绘制路线
      markerDetailPop: false,
      searchPop: false,
      // isShowMember: false,
      isShowArea: false,
      isPackUp: true,
      covers: [],
      markerDetail: {},
      reviewList: [],
      routeList: [],
      geolocation: null,
      nowLocation: {},
      polygons: [],
      search_keyword: '',
      isSearchResult: false,
      history_keyword_list: [],
      lines: '',
      addressLineList: {},
      isDoubleTouch: false,
      touchesStart: [],
      scaleZoom: 1,
      // scaleing: 1,
      screenMinPoint: [],
      nowScrollTop: 0,
      nowScrollLeft: 0,
      map_obj: {},
      is_big: false,
      distance: 0, //手指移动的距离
      start_end_icon: [],
      is_show_distance: false,
      audioAction: {
        method: 'pause',
      },
      is_show_more: true,
      isFenghuanghu: false, // 是否凤凰湖景区
      isMobile: false, // 判断手机端
      is_gf: '',
      isExplaining: false,
      scence_advert_list: [],
      adRightList: []
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIsMobile)
  },
  mounted() {
    window.addEventListener('resize', this.checkIsMobile)
    this.checkIsMobile()
    let url = window.location.hash.split('?')[1].split('&')
    url.forEach((item) => {
      this.options[item.split('=')[0]] = item.split('=')[1]
    })
    console.log(this.options, 'options')
    this.is_gf = this.options.is_gf
    this.screenHeight = window.innerHeight
    this.screenWidth = window.innerWidth
    var _this = this

    // 监听手机窗口变化，重新设置
    let map_box = document.getElementById('map_box')
    console.log(document.getElementById('map').offsetWidth)
    map_box.addEventListener(
      'touchstart',
      function (e) {
        if (e.touches.length >= 2) {
          //判断是否有两个点在屏幕上
          e.preventDefault()
          _this.isDoubleTouch = true
          map_box.style.overflowX = 'auto'
          map_box.style.overflowY = 'auto'
          // _this.touchesStart = e.touches; //得到第一组两个点
          // 当两根手指放上去的时候，将距离(distance)初始化。
          let xMove = e.touches[1].clientX - e.touches[0].clientX
          let yMove = e.touches[1].clientY - e.touches[0].clientY
          //计算开始触发两个手指坐标的距离
          let distance = Math.sqrt(xMove * xMove + yMove * yMove)
          _this.distance = distance
        }
      },
      false
    )
    //监听touchmove事件
    map_box.addEventListener(
      'touchmove',
      function (e) {
        if (e.touches.length >= 2 && _this.isDoubleTouch) {
          //双手指运动 x移动后的坐标和y移动后的坐标
          let xMove = e.touches[1].clientX - e.touches[0].clientX
          let yMove = e.touches[1].clientY - e.touches[0].clientY
          //双手指运动新的 ditance
          let distance = Math.sqrt(xMove * xMove + yMove * yMove)
          //计算移动的过程中实际移动了多少的距离
          let distanceDiff = distance - _this.distance
          let newScale = _this.scaleZoom + 0.002 * distanceDiff
          // 为了防止缩放得太大，所以scale需要限制，同理最小值也是
          if (newScale <= 1) {
            _this.distance = distance
            newScale = 1
            _this.scaleZoom = newScale
          } else if (newScale >= 2) {
            newScale = 2
            _this.scaleZoom = newScale
            _this.distance = distance
          } else {
            _this.distance = distance
            _this.scaleZoom = newScale
            map_box.scrollTop =
              map_box.scrollTop +
              _this.imgHeight * _this.zoom * 0.001 * distanceDiff
            map_box.scrollLeft =
              map_box.scrollLeft +
              _this.imgWidth * _this.zoom * 0.001 * distanceDiff
          }
          _this.lineChange()
        }
      },
      false
    )
    //监听touchend事件
    map_box.addEventListener(
      'touchend',
      function () {
        if (_this.isDoubleTouch) {
          _this.isDoubleTouch = false
          map_box.style.overflowX = 'auto'
          map_box.style.overflowY = 'auto'
        }
      },
      false
    )
    this.history_keyword_list = history.getHistoryStorage('shop').reverse()
    if (this.options.session_id) {
      localStorage.setItem('session_id', this.options.session_id)
    } else {
      localStorage.setItem('session_id', '')
    }
    if (this.options.s_id) {
      this.scenic = this.options.s_id
      if (this.scenic == '2935878fd8bb472597058a92e8769f8d') {
        this.isFenghuanghu = true
      }
      // this.getAreaList(2)
      this.$forceUpdate()
      this.getHandDrawnMap(this.options.s_id)
      this.getScenicData(this.options.s_id)
      this.getUserLocation()
    } else if (this.options.data) {
      var share_data = JSON.parse(this.options.data)
      this.scenic = share_data.scenic_id
      if (share_data.usersPosition) {
        this.share_latlng = share_data.usersPosition
      }
      this.share_user_id = share_data.share_user_id
      this.getHandDrawnMap(share_data.scenic_id)
      this.getScenicData(share_data.scenic_id)
      this.getUserLocation(1)
      // this.getTeamLocation()
    }
    this.getPointList()
    this.getRecommendKeywordApi()
    if (this.options.p_id && this.options.p_id != undefined && this.options.p_id != 'undefined') {
      var obj = {
        detail: { id: this.options.p_id },
      }
      this.markerTap(obj, false)
    }
    // this.geolocation = new window.qq.maps.Geolocation("IEZBZ-K3N3Q-L635O-4VXNC-RD62O-XOBTV", "myapp");
    this.geolocation = new window.BMapGL.Geolocation()
    console.log()
  },
  methods: {
    advNavigateTo(url) {
      wx.miniProgram.navigateTo({
        url: url,
      })
    },
    startExplaining() {
      this.isExplaining = true
    },
    clickImg() {
      this.markerDetailPop = false
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768
      console.log(this.isMobile, 'this.isMobile')
    },
    getHandDrawnMap(id) {
      getHandDrawnMap({ id: id }).then((res) => {
        this.map_obj = res.data.data.hand_drawn_map_info_item_list[0]
        var img = new Image()
        img.src = this.oss + this.map_obj.img_url
        var _this = this
        img.onload = function () {
          (_this.imgWidth = img.width), (_this.imgHeight = img.height)
          if (
            _this.imgWidth / _this.screenWidth <
            _this.imgHeight / _this.screenHeight
          ) {
            _this.zoom = _this.screenWidth / _this.imgWidth
            let map_box = document.getElementById('map_box')
            map_box.scrollTo(
              0,
              (_this.imgHeight / 2) * _this.zoom - _this.screenHeight / 2
            )
          } else {
            _this.zoom = _this.screenHeight / _this.imgHeight
            let map_box = document.getElementById('map_box')
            map_box.scrollTo(
              (_this.imgWidth / 2) * _this.zoom - _this.screenWidth / 2,
              0
            )
          }
        }
        _this.getFourLocation()
        setTimeout(function () {
          if (_this.options.s_id) {
            _this.getAddressApi(_this.options.s_id)
          } else if (_this.options.data) {
            var share_data = JSON.parse(_this.options.data)
            _this.getAddressApi(share_data.scenic_id)
          }
        }, 666)
      })
    },
    // 两点的距离
    getDistance(p1, p2) {
      var x = p2.pageX - p1.pageX,
        y = p2.pageY - p1.pageY
      return Math.sqrt(x * x + y * y)
    },
    // 获取中点
    getMidpoint(p1, p2) {
      var x = (p1.pageX + p2.pageX) / 2,
        y = (p1.pageY + p2.pageY) / 2
      return [x, y]
    },
    getFourLocation() {
      // this.mapLocation.leftTop = this.bMapTransqqMap(115.54965318392219, 28.37339607139829)
      // this.mapLocation.leftBottom = this.bMapTransqqMap(115.54965318392219, 28.36733250534208)
      // this.mapLocation.rightTop = this.bMapTransqqMap(115.5573201702714, 28.37339607139829)
      // this.mapLocation.rightBottom = this.bMapTransqqMap(115.5573201702714, 28.36733250534208)
      // console.log(this.mapLocation, '地图啊')
      var northeast_corner_coordinates =
        this.map_obj.northeast_corner_coordinates.split(',')
      var northwest_corner_coordinates =
        this.map_obj.northwest_corner_coordinates.split(',')
      var southeast_corner_coordinates =
        this.map_obj.southeast_corner_coordinates.split(',')
      var southwest_corner_coordinates =
        this.map_obj.southwest_corner_coordinates.split(',')
      this.mapLocation.leftTop = {
        longitude: northwest_corner_coordinates[0],
        latitude: northwest_corner_coordinates[1],
      }
      this.mapLocation.leftBottom = {
        longitude: southwest_corner_coordinates[0],
        latitude: southwest_corner_coordinates[1],
      }
      this.mapLocation.rightTop = {
        longitude: northeast_corner_coordinates[0],
        latitude: northeast_corner_coordinates[1],
      }
      this.mapLocation.rightBottom = {
        longitude: southeast_corner_coordinates[0],
        latitude: southeast_corner_coordinates[1],
      }
      // console.log(this.mapLocation,'mapLocation')
      // 计算地图范围
      this.location_range.lng =
        this.mapLocation.rightBottom.longitude -
        this.mapLocation.leftTop.longitude
      this.location_range.lat =
        this.mapLocation.leftTop.latitude -
        this.mapLocation.rightBottom.latitude
    },
    // 区域获取
    getAddressApi(id) {
      getAddressApi({ id: id }).then((res) => {
        this.addressLineList = res.data.data
        // 计算线
        for (let i = 0; i < res.data.data.area_info_item_list.length; i++) {
          var arr = res.data.data.area_info_item_list[i]
          this.polylines.push({
            color: arr.color,
            line: [],
          })
          for (let j = 0; j < arr.area_info_item_list.length; j++) {
            var lng_lat = arr.area_info_item_list[j].lng_lat.split(',')
            var rangd_obj = this.countRange(lng_lat[1], lng_lat[0])
            this.polylines[i].line.push({
              lat_on_map: rangd_obj.lat_percent * this.imgHeight * this.zoom,
              lng_on_map: rangd_obj.lng_percent * this.imgWidth * this.zoom,
            })
          }
        }
        // this.lineChange()
      })
    },
    // 计算打点位置比例
    countRange(lat, lng) {
      var lat_range = this.mapLocation.leftTop.latitude - lat
      var lng_range = lng - this.mapLocation.leftTop.longitude
      var lat_percent = (lat_range / this.location_range.lat).toFixed(5)
      var lng_percent = (lng_range / this.location_range.lng).toFixed(5)
      var obj = {}
      if (lat_percent <= 1 && lng_percent <= 1) {
        obj = { lat_percent: lat_percent, lng_percent: lng_percent }
      } else {
        obj = { lat_percent: null, lng_percent: null }
      }
      return obj
    },
    // 路线切换
    lineChange() {
      const lineArray = []
      this.start_end_icon = []
      if (this.lineIndex == -1) {
        for (
          let i = 0;
          i < this.addressLineList.area_info_item_list.length;
          i++
        ) {
          for (let j = 1; j < this.polylines[i].line.length; j++) {
            if (j == 1) {
              this.start_end_icon.push({
                lng_percent:
                  this.polylines[i].line[j - 1].lng_on_map * this.scaleZoom,
                lat_percent:
                  this.polylines[i].line[j - 1].lat_on_map * this.scaleZoom,
                icon: 1,
                content: '起',
              })
            } else if (j == this.polylines[i].line.length - 1) {
              this.start_end_icon.push({
                lng_percent:
                  this.polylines[i].line[j].lng_on_map * this.scaleZoom,
                lat_percent:
                  this.polylines[i].line[j].lat_on_map * this.scaleZoom,
                icon: 2,
                content: '终',
              })
            }
            lineArray.push(
              this.setLine(
                this.polylines[i].line[j - 1],
                this.polylines[i].line[j],
                this.polylines[i].color
              )
            )
          }
        }
      } else {
        if (this.lineIndex !== null) {
          for (let z = 1; z < this.polylines[this.lineIndex].line.length; z++) {
            if (z === 1) {
              this.start_end_icon.push({
                lng_percent:
                  this.polylines[this.lineIndex].line[z - 1].lng_on_map *
                  this.scaleZoom,
                lat_percent:
                  this.polylines[this.lineIndex].line[z - 1].lat_on_map *
                  this.scaleZoom,
                icon: 1,
                content: '起',
              })
            } else if (z == this.polylines[this.lineIndex].line.length - 1) {
              this.start_end_icon.push({
                lng_percent:
                  this.polylines[this.lineIndex].line[z].lng_on_map *
                  this.scaleZoom,
                lat_percent:
                  this.polylines[this.lineIndex].line[z].lat_on_map *
                  this.scaleZoom,
                icon: 2,
                content: '终',
              })
            }
            lineArray.push(
              this.setLine(
                this.polylines[this.lineIndex].line[z - 1],
                this.polylines[this.lineIndex].line[z],
                this.polylines[this.lineIndex].color
              )
            )
          }
        }
      }
      this.lines = lineArray.join('')
    },
    getPointList() {
      getMapPointList({ is_map: 1, scenic_biz_id: this.scenic }).then((res) => {
        this.categoryPoiList = res.data.data
        if (this.isFenghuanghu) {
          this.categoryPoiList.forEach((item) => {
            this.$set(item, 'is_default_display', true)
          })
        }
        if (this.categoryPoiList.length > 0 && this.share_latlng == null) {
          if (this.is_show_more) {
            for (let i = 0; i < this.categoryPoiList.length; i++) {
              if (this.categoryPoiList[i].is_default_display) {
                this.clickPoi(this.categoryPoiList[i], -1)
              }
            }
          } else {
            for (let i = 0; i < this.categoryPoiList.length; i++) {
              if (i == 0) {
                this.categoryPoiList[0].is_default_display = true
                this.clickPoi(this.categoryPoiList[0], 0, true)
              } else {
                this.categoryPoiList[i].is_default_display = false
                this.clickPoi(this.categoryPoiList[i], i, true)
              }
            }
          }
        }
      })
    },
    getScenicData(id) {
      getScenicIndex({ scenic_id: id }).then((res) => {
        console.log(res);
        if (res.data.data.scence_advert_list.length > 0 || res.data.data.scence_advert_list) { // 场景广告优先显示
          const currentTime = new Date();
          const currentHour = String(currentTime.getHours()).padStart(2, '0');
          const currentMinute = String(currentTime.getMinutes()).padStart(2, '0');
          const currentFormattedTime = `${currentHour}:${currentMinute}`;
          // 过滤符合条件的广告（广告位推荐）
          this.scence_advert_list = res.data.data.scence_advert_list.filter(ad => {
            if (ad.display === 1) {
              const timeRanges = JSON.parse(ad.timeranges.replace(/'/g, '"')); // 解析timeranges
              return timeRanges.some(range => {
                return currentFormattedTime >= range.start_time && currentFormattedTime <= range.end_time;
              });
            }
            return false;
          });
          // 右下角展示
          this.adRightList = res.data.data.scence_advert_list.filter(ad => {
            if (ad.display === 0) {
              const timeRanges = JSON.parse(ad.timeranges.replace(/'/g, '"')); // 解析timeranges
              return timeRanges.some(range => {
                return currentFormattedTime >= range.start_time && currentFormattedTime <= range.end_time;
              });
            }
            return false;
          });
        } else {
          this.advertList = res.data.data.advert_list
        }
      })
    },
    getUserLocation(state) {
      wx.getLocation({
        type: 'wgs84',
        success: (res) => {
          this.user_location = res.data.data
          if (this.myLocation || state == 1) {
            this.location.longitude = res.data.data.longitude
            this.location.latitude = res.data.data.latitude
          }
        },
      })
    },
    clickMyLocation(num, params) {
      // this.myLocation = !this.myLocation
      // if (this.myLocation) {
      //   this.getUserLocation()
      // } else {
      //   this.location = {...this.scenic_latAndlng}
      // }
      this.geolocation.getCurrentPosition((data) => {
        // this.user_location = { latitude: 28.370039268325718, longitude: 115.5536317253959 }
        this.user_location = {
          latitude: data.point.lat,
          longitude: data.point.lng,
        }
        if (num !== 1) {
          if (!this.myLocation) {
            // this.nowLocation = this.countRange(28.370039268325718, 115.5536317253959)
            this.nowLocation = this.countRange(data.point.lat, data.point.lng)
            if (this.nowLocation.lat_percent != null) {
              this.myLocation = true
            } else {
              this.$toast('距离太远没法定位')
            }
          } else {
            this.myLocation = false
          }
        } else {
          this.getSearchRoute(params)
        }
      })
    },
    clickRoute() {
      this.isShowRouteItem = !this.isShowRouteItem
      // this.isShowRouteDetails = false
    },
    clickLine(item, index) {
      if (this.lineIndex === index) {
        this.lineIndex = null
      } else {
        this.lineIndex = index
        this.lineList = {}
      }
      this.lineChange()
    },
    searchRoute(data) {
      var params = []
      data.forEach((item) => {
        params.push({
          lat: item.lat,
          lng: item.lng,
        })
      })
      this.clickMyLocation(1, params)
    },
    getSearchRoute(params) {
      // var lng_lat_baidu =  this.convert2BMap(this.user_location.longitude, this.user_location.latitude)
      var lng_lat_baidu = {
        longitude: this.user_location.longitude,
        latitude: this.user_location.latitude,
      }
      params.unshift({
        lat: lng_lat_baidu.latitude,
        lng: lng_lat_baidu.longitude,
      })
      this.markerDetailPop = false
      this.isSearchResult = false
    },
    setLine(start, end, color) {
      const lx =
        end.lng_on_map * this.scaleZoom - start.lng_on_map * this.scaleZoom
      const ly =
        end.lat_on_map * this.scaleZoom - start.lat_on_map * this.scaleZoom
      // 计算连线长度
      const length = Math.sqrt(lx * lx + ly * ly)
      // 弧度值转换为角度值
      const c = (360 * Math.atan2(ly, lx)) / (2 * Math.PI)
      const midX =
        (end.lng_on_map * this.scaleZoom + start.lng_on_map * this.scaleZoom) /
        2
      const midY =
        (end.lat_on_map * this.scaleZoom + start.lat_on_map * this.scaleZoom) /
        2
      const deg = c <= -90 ? 360 + c : c // 负角转换为正角
      return `<div
                style='top:${midY}px;left:${(midX - length / 2).toFixed(
        2
      )}px;width:${length.toFixed(2)}px;transform:rotate(${deg.toFixed(2)}deg);
                  position: absolute;height: 3px;background-color:${color};'>
            </div>`
    },
    getSecnicInfo(id) {
      getWeather({ scenic_id: id }).then((res) => {
        this.map_default_display = res.data.data.map_default_display
        this.location.latitude = res.data.data.scenic_lat
        this.location.longitude = res.data.data.scenic_lng
        this.scenic_latAndlng = {
          longitude: res.data.data.scenic_lng,
          latitude: res.data.data.scenic_lat,
        }
        this.location = { ...this.scenic_latAndlng }
      })
    },
    startNav() {
      this.searchRoute(this.routeList[this.lineIndex].poi_list)
    },
    delLine(item) {
      if (this.lineIndex === 0) {
        delUserRouter({ map_point_id: item.point_id }).then(() => {
          var idx = this.routeList[this.lineIndex].poi_list.findIndex(
            (x) => x.point_id === item.point_id
          )
          this.routeList[this.lineIndex].poi_list.splice(idx, 1)
          // this.getRouter('1')
          this.$toast({
            title: '删除成功',
            icon: 'none',
          })
        })
      } else {
        var idx = this.routeList[this.lineIndex].poi_list.findIndex(
          (x) => x.point_id === item.point_id
        )
        this.routeList[this.lineIndex].poi_list.splice(idx, 1)
      }
    },
    async markerTap(e, state) {
      console.log(e, '点击景点')
      this.isExplaining = state
      await this.getUserLocation()
      var biz_id = null
      if (e.detail && e.detail.id) {
        biz_id = e.detail.id
      } else {
        biz_id = this.covers[e].biz_id
      }
      getPointDetail({ id: biz_id }).then((res) => {
        console.log(res, 'res')
        if (res) {
          this.markerDetailPop = true
          this.markerDetail = res.data.data
          // this.markerDetail.introduce =  res.data.data.introduce.replace(/\n/g,'<br>')
          this.markerDetail.introduce = res.data.data.introduce.replace(/\n/g, '<br>');
          // 然后，用两个空格对每一行进行缩进
          this.markerDetail.introduce = this.markerDetail.introduce.replace(/(^|<br>)/g, '$1  ');
          
        }
        this.getPointReviewList(biz_id)
      })
      // const systemSetting = wx.getSystemSetting()
      // if(systemSetting.locationEnabled) {

      // } else {
      //   this.$toast({
      //     title: '请先打开手机定位',
      //     icon: 'none'
      //   });
      // }
    },
    getPointReviewList(id) {
      getPointReview({ point_id: id }).then((res) => {
        this.reviewList = res.data.data
      })
    },
    goTicketPage() {
      wx.miniProgram.navigateTo({
        url: '../../../../pages/tabbar/order/order',
      })
    },
    toVr(name) {
      if (this.is_gf === '1') {
        this.$router.push({
          path: './FenghuabghuVr',
          query: { scenic_id: this.scenic, points: name },
        })
      } else {
        wx.miniProgram.navigateTo({
          url:
            '../../../../pages/tabbar/guide/vr/vr?scenic_id=' +
            this.scenic +
            '&points=' +
            name,
        })
      }
    },
    goConsultationFeedbackPage() {
      wx.miniProgram.navigateTo({
        url:
          '../../../../complaints-pages/pages/consultingFeedback?scenic=' +
          this.scenic,
      })
    },
    goofficiaWeb() {
      this.$router.push({ path: './offciaWeb' })
    },
    goVr() {
      this.$router.push({
        path: './FenghuabghuVr',
        query: { scenic_id: this.scenic },
      })
    },
    searchBtn() {
      this.searchPop = true
    },
    getRecommendKeywordApi() {
      getKeywordList({ keyword_type: '2' }).then((res) => {
        this.keyWordList = res.data.data.data_list2
      })
    },
    getRouter(router_type) {
      this.routeList = []
      getRouterList({ router_type: router_type }).then((res) => {
        if (res.data.data.user_router) {
          this.routeList.unshift(res.data.data.user_router)
        }
        res.data.data.scenic_router_list.forEach((item) => {
          this.routeList.push(item)
        })
        this.routeList.forEach((item, index) => {
          if (item) {
            if (index === 0) {
              item.names = '我的线路'
            } else {
              item.names = '推荐线路' + index
            }
          }
        })
      })
    },
    clickPoi(item, data, is_h) {
      var checked
      if (data === -1) {
        this.$set(item, 'is_default_display', true)
      } else {
        if (is_h) {
          checked = item.is_default_display
          this.$set(item, 'is_default_display', checked)
        } else {
          if (this.is_show_more) {
            checked = !item.is_default_display
            this.$set(item, 'is_default_display', checked)
          } else {
            checked = !item.is_default_display
            this.$set(item, 'is_default_display', checked)
            for (let i = 0; i < this.categoryPoiList.length; i++) {
              if (i != data) {
                this.categoryPoiList[i].is_default_display = false
              }
            }
          }
        }
      }
      this.covers = []
      this.categoryPoiList.forEach((items) => {
        if (items.is_default_display) {
          items.poi_list.forEach((x) => {
            // var list = this.bMapTransqqMap(x.lng, x.lat)
            // let latitude = Number(list.latitude)
            // let longitude = Number(list.longitude)
            let latitude = Number(x.lat)
            let longitude = Number(x.lng)
            this.covers.push({
              biz_id: x.biz_id,
              icon: items.icon,
              position: this.countRange(latitude, longitude),
              content: x.name,
            })
          })
        }
      })
    },
    bMapTransqqMap(lng, lat) {
      // 百度经纬度转腾讯经纬度
      let x_pi = (3.14159265358979324 * 3000.0) / 180.0
      let x = lng - 0.0065
      let y = lat - 0.006
      let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
      let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
      let lngs = z * Math.cos(theta)
      let lats = z * Math.sin(theta)
      return {
        longitude: lngs,
        latitude: lats,
      }
    },
    //腾讯坐标转换成百度坐标
    // convert2BMap
    convert2BMap(dataLon, dataLat) {
      let bd_lat
      let bd_lon
      let x_pi = 3.14159265358979324
      let x = dataLon,
        y = dataLat
      let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi)
      let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi)
      bd_lon = z * Math.cos(theta) + 0.0065
      bd_lat = z * Math.sin(theta) + 0.006
      return {
        longitude: bd_lon,
        latitude: bd_lat,
      }
    },
    packUp() {
      this.isPackUp = !this.isPackUp
    },
    go_write_comment(item) {
      // wx.miniProgram.navigateTo
      wx.miniProgram.navigateTo({
        url:
          '/pages/tabbar/guide/map-review/map-review?point_id=' + item.biz_id,
      })
    },
    // getAreaList(state) {
    //   getAreaInfo({ id: this.scenic}).then(res => {
    //     console.log(res.data, '干嘛的')
    //     if (state === 1) {
    //       res.data.data.area_info_item_list.forEach(item => {
    //         item.points = []
    //         item.area_info_item_list.forEach(items => {
    //           let arr = items.lng_lat.split(',')
    //           // items.bmtAddress = this.bMapTransqqMap(arr[0], arr[1])
    //           items.bmtAddress = { longitude: arr[0], latitude: arr[1] }
    //           item.points.push({
    //             latitude: items.bmtAddress.latitude,
    //             longitude: items.bmtAddress.longitude
    //           })
    //         })
    //         this.polygons.push({
    //           points: item.points,
    //           width: 2,
    //           fillColor: item.color +'3B', //填充颜色
    //           strokeColor: item.color, //描边颜色
    //           strokeWidth: 2, //描边宽度
    //           zIndex: 1, //层级
    //         })
    //       })
    //     } else {
    //       this.minScale = res.data.data.area_display_min
    //       this.maxScale = res.data.data.area_display_max
    //       this.icon_display = {
    //         icon_display_max: res.data.data.icon_display_max,
    //         icon_display_min: res.data.data.icon_display_min,
    //       }
    //       this.instructions = {
    //         instructions_display_min: res.data.data.instructions_display_min,
    //         instructionsarea_display_max: res.data.data.instructionsarea_display_max
    //       }
    //     }
    //   })
    // },
    go_to_search(e) {
      if (!e.detail.value) {
        this.isSearchResult = false
      } else {
        getMapPointList({ keyword: e.detail.value }).then((res) => {
          this.isSearchResult = true
          this.searchResultList = res.data.data
        })
      }
    },
    search_for_keyword(keyword) {
      this.search_keyword = keyword
      if (
        history.getHistoryStorage('shop').indexOf(keyword) == -1 &&
        keyword != '' &&
        keyword != null &&
        keyword != undefined
      ) {
        history.pushStorage('shop', keyword)
      }
      this.history_keyword_list = history.getHistoryStorage('shop').reverse()
      var params = { detail: { value: keyword } }
      this.go_to_search(params)
    },
    del_history_search() {
      // 清除历史搜索
      history.clearStorage('shop')
      this.history_keyword_list = history.getHistoryStorage('shop').reverse()
    },
    closeSearchPop() {
      this.searchPop = false
    },
    goSearchPoint(data) {
      var cutting = data.lng_lat.split(',')
      var params = { lat: cutting[1], lng: cutting[0] }
      this.searchRoute([params])
      this.searchPop = false
    },
    preImg(imgArr, index) {
      // 预览大图
      var newList = []
      imgArr.forEach((item) => {
        newList.push(this.oss + item.file_path)
      })
      const urls = newList
      const current = index
      wx.previewImage({
        urls,
        current,
      })
    },
    isShowMore() {
      this.is_show_more = !this.is_show_more
      this.getPointList()
    },
  },
}
</script>
<style lang="scss" scoped>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
.map-box {
  display: grid;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  overscroll-behavior: contain;
  .map_img {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .map-icon {
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    // z-index: 1;
    img {
      display: inline-block;
      width: 26px;
      height: 26px;
    }
    .icon_text {
      display: block;
      white-space: nowrap;
      padding: 1px 2px;
      font-size: 8px;
      color: #37373d;
      border: 1px solid #37373d;
      background-color: #fff;
      border-radius: 6px;
    }
  }
  .map-iconPc {
    img {
      display: inline-block;
      width: 3rem;
      height: 3rem;
    }
    .icon_text {
      font-size: 20px;
    }
  }
}
.mapPop {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
}
.noneAdv {
  height: calc(100vh);
}
.hasAdvHeight {
  height: calc(100vh - 71px);
}
.recommend {
  margin: 0 auto;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.08), 1px 0 1px 0 rgba(0, 0, 0, 0.08),
    0 -1px 0 0 rgba(0, 0, 0, 0), 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  .recommend-img {
    width: 100%;
    image {
      width: 100%;
      height: 71px;
    }
    .borTopRad {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}
.mapTopItem {
  height: 27px;
  .ticketIcon {
    width: 50px;
    background: #fff;
    border-radius: 15px;
    padding: 4px 10px;
    .icon_ticket {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
.typeface {
  font-size: 11px;
}
.typefacePc {
  font-size: 22px;
}
.typefaceBorder {
  border-bottom: 1px solid #eee;
  padding-bottom: 7px;
}
.selectedFont {
  color: #138eff;
}
.searchBtn {
  background-color: #fff;
  border-radius: 6px;
  padding: 5px;
  margin-left: 10px;
  // margin-top:55px;
  .searchIcon {
    width: 20px;
    height: 20px;
  }
  .searchTypeface {
    font-size: 12px;
    // margin-top: 4px;
    height: 20px;
    line-height: 20px;
    margin-left: 6px;
  }
}
.functionalZone {
  background-color: #fff;
  border-radius: 6px;
  padding: 6px;
  margin-top: 46px;
  box-shadow: 0 0 10px 1px #ddd;
  position: fixed;
  .functionalZoneItem {
    padding: 4px 0;
    .functionalZoneItemIcon {
      width: 20px;
      height: 20px;
    }
    .typefaceTop {
      margin-top: 4px;
    }
  }
  .functionalZoneItem:last-child {
    border-bottom: none;
  }
}
.functionalZonePc {
  margin-top: 185px;
  .functionalZoneItem {
    .functionalZoneItemIcon {
      width: 4rem;
      height: 4rem;
    }
  }
}
.locationBgcPc {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  border-radius: 50%;
  .locationImg {
    width: 4rem;
    height: 4rem;
  }
}
.locationBgc {
  border-radius: 50%;
  padding: 8px;
  width: 25px;
  height: 25px;
  box-shadow: 0 0 10px 1px #ddd;
  .locationImg {
    width: 25px;
    height: 25px;
  }
}
.bottomIconList {
  position: fixed;
  bottom: 10px;
  width: 90%;
  z-index: 9;
  left: 5%;
}
.bottomIconListFeng {
  position: fixed;
  top: 18px;
  width: 90%;
  z-index: 9;
  left: 5%;
}
.poiListPc {
  padding: 15px 0;
  height: 90px;
  background-color: #fff;
  border-radius: 60px;
  overflow-x: scroll;
  position: relative;
  width: 26px;
  box-shadow: 0 0 10px 1px #ddd;
  .poiListLeft {
    padding-left: 9px;
    overflow-x: scroll;
    padding-right: 30px;
    .poiItem {
      padding: 0 15px;
      .poiIconPc {
        width: 4rem;
        height: 4rem;
      }
      .poiText {
        width: 75px;
        margin-top: 2px;
        text-align: center;
        overflow: hidden;
        font-size: 18px;
      }
    }
  }
}
.poiList {
  background-color: #fff;
  border-radius: 30px;
  overflow-x: scroll;
  position: relative;
  height: 50px;
  width: 26px;
  box-shadow: 0 0 10px 1px #ddd;
  .poiListLeft {
    padding-left: 9px;
    overflow-x: scroll;
    padding-right: 30px;
    .poiItem {
      margin-right: 2px;
      .poiIcon {
        width: 25px;
        height: 25px;
      }
      .poiText {
        width: 48px;
        height: 18px;
        margin-top: 2px;
        text-align: center;
        overflow: hidden;
      }
    }
  }
}
.icon_map_right {
  padding: 0 4px;
  position: absolute;
  right: 0;
  background: #fff;
  height: 50px;
  .icon_map_right_arrow {
    width: 18px;
    height: 18px;
  }
}
.routeLine {
  padding: 14px 5px;
  color: #999;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  width: 72%;
  right: 35px;
  top: 75px;
  font-size: 13px;
  overflow: hidden;
  overflow-x: auto;
  .routeLineItem {
    border-right: 1px solid #ddd;
    padding: 0 5px;
    min-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .routeLineItemPc {
    min-width: 100px;
    border-right: 1px solid #ddd;
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .routeLineItem:last-child {
    border-right: none;
  }
}
.recommendRoute {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 92%;
  height: 240px;
  left: 0;
  border-radius: 25px 25px 0 0;
  padding: 4%;
  z-index: 100;
  .pathTitle {
    font-size: 20px;
    font-weight: 600;
  }
  .presetTime {
    color: #aaa;
    font-size: 13px;
    margin-top: 5px;
  }
  .beginNav {
    background: #138eff;
    color: #fff;
    width: 100px;
    height: 34px;
    border-radius: 25px;
  }
  .pathList {
    margin-top: 10px;
    height: 180px;
    overflow-y: scroll;
    .pathItem {
      background-color: #ececec;
      border-radius: 8px;
      margin-right: 8px;
      font-size: 13px;
      margin-bottom: 8px;
      height: 30px;
      .pathName {
        padding: 8px 10px;
        border-right: 1px solid #ddd;
      }
      .closeBtn {
        padding: 8px;
        width: 12px;
        height: 12px;
      }
    }
  }
}
.markerDetail {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  height: 500px;
  border-radius: 20px 20px 0 0;
  z-index: 101;
  overflow-y: scroll;
  padding-top: 15px;
  .markerItem {
    padding: 0 4% 4%;
    border-bottom: 8px solid #f6f6f6;
    .markerItemTop {
      .markerTitle {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
      }
      .btn {
        color: #fff;
        padding: 5px 15px;
        border-radius: 17px;
        font-size: 15px;
      }
      .joinGuide {
        background-color: #3b8efb;
        border: 1px solid #8bafd1;
      }
    }
    .markerContent {
      font-size: 15px;
      white-space: pre-wrap;
      line-height: 20px;
      margin: 10px 0;
    }
    .markerContentnNomore {
      font-size: 15px;
      white-space: pre-wrap;
      line-height: 20px;
      margin: 10px 0;
      height: 61px;
    }
    .distance {
      color: #999999;
      font-size: 13px;
    }
    .distance_text {
      color: #999999;
      font-size: 14px;
      text-align: left;
      font-size: 14px;
      margin: 10px 0;
    }
    .is_show_distance {
      text-align: right;
      font-size: 12px;
      color: #2ba245;
    }
    .ellipsis-multi-line {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
    .markerImg {
      width: 100%;
      margin-top: 10px;
      overflow: hidden;
      overflow-x: auto;
      flex-wrap: nowrap;
      .markerImgItem {
        width: 100px;
        height: 100px;
        margin: 10px 3% 0 0;
        .markerImgItemImg {
          height: 100px;
          width: 100px;
          border-radius: 10px;
        }
      }
    }
  }
  .remarkOn {
    padding: 4% 4% 60px;
    .remarkOnTitle {
      font-size: 17px;
      font-weight: 600;
    }
    .remarkOnContent {
      .remarkOnItem {
        width: 100%;
        margin-bottom: 15px;
        .remarkOnItemUser {
          .remarkOnItemLeft {
            border-radius: 5px;
            .remarkOnImg {
              width: 40px;
              height: 40px;
            }
            .remarkOnName {
              font-size: 14px;
              margin-left: 8px;
            }
          }
          .remarkOnItemRight {
            font-size: 13px;
            color: #aaa;
          }
        }
        .remarkOnText {
          margin: 5px 0;
        }
        .remarkOnList {
          margin-top: 10px;
          .imgItem {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .operating {
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
    .operatingBtn {
      width: 25%;
      padding: 9px 0;
      border: 1px solid #aaa;
      border-radius: 27px;
      text-align: center;
      color: #fff;
      font-size: 15px;
    }
    .operatingBtnBgc {
      background-color: #3b8efb;
    }
    .btnText {
      color: #aaa;
    }
  }
  .searchRegion {
    margin: 9px 4% 0;
    .search-top {
      background: #ffffff;
      border-radius: 14px;
      height: 36px;
      .search-ctn {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 36px;
        padding-left: 16px;
        width: 83%;
        border-radius: 18px;
        border: 1px solid #dadada;
        .search-icon {
          width: 14px;
          height: 14px;
          background: url(../../static/img/icon_grey_search.png) no-repeat;
          background-size: 100%;
          margin-right: 12px;
        }
        .search-input {
          width: 80%;
          font-size: 14px;
          font-weight: 400;
          color: #999;
          line-height: 14px;
          .input-val {
            width: 100%;
            color: #1f1f1f;
            border: 0 solid #fff;
          }
        }
      }
      .cancel-search {
        margin-left: 6px;
        font-size: 14px;
        font-weight: 600;
        color: #1f1f1f;
        height: 36px;
        line-height: 36px;
      }
    }
    .searchResult {
      margin-top: 10px;
      .noMore {
        margin-top: 45px;
        font-size: 15px;
      }
      .searchResultItem {
        padding: 11px 0;
        border-bottom: 1px solid #ddd;
        .searchResultItemLeft {
          .searchResultItemLeftImg {
            width: 15px;
            height: 15px;
          }
          .searchResultItemInfo {
            margin-left: 10px;
            .searchResultItemInfoName {
              .name {
                font-size: 15px;
                font-weight: 600;
                margin-right: 8px;
              }
              .classTag {
                font-size: 12px;
                padding: 3px 8px;
                background: #fcf0e4;
                color: #f3b57c;
                border-radius: 3px;
              }
            }
            .searchResultItemNickname {
              margin: 4px 0;
              font-size: 13px;
              color: #b2b2b2;
            }
            .searchResultItemAdress {
              font-size: 13px;
              color: #b2b2b2;
            }
          }
        }
        .searchResultItemRight {
          .searchResultItemRightImg {
            width: 15px;
            height: 15px;
          }
          .searchResultItemRightText {
            margin-top: 8px;
            font-size: 13px;
            color: #8d8d8d;
          }
        }
      }
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
.search-section {
  margin: 24px 10px 0;
  position: relative;
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    line-height: 18px;
    margin-left: 6px;
  }
  .delete {
    position: absolute;
    width: 16px;
    height: 16px;
    background-image: url(../../static/img/icon_delete.png);
    background-size: 100%;
    background-repeat: no-repeat;
    top: 0;
    right: 12px;
  }
  .search-keyword-list {
    margin-top: 20px;
    flex-wrap: wrap;
    .keyword-item {
      background: #f4f4f4;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #333;
      line-height: 33px;
      height: 32px;
      margin: 0 1.66% 12px;
      text-align: center;
      width: 11%;
      padding: 0 5%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.btns {
  margin: 5%;
  position: fixed;
  z-index: 9;
  width: 90%;
}
.menberBtn {
  width: 100px;
  height: 40px;
  padding: 0;
  font-size: 13px;
  position: absolute;
  right: 34px;
  top: 65px;
  line-height: 40px;
  color: #3b8efb;
  background: #fff;
}
.flex {
  display: flex;
}
.flex-co {
  flex-direction: column;
}
.flex-c {
  justify-content: center;
  align-items: center;
}
.flex-jc-sb {
  justify-content: space-between;
}
.flex-jc-fe {
  justify-content: flex-end;
}
.flex_s_b {
  justify-content: space-between;
}
.flex_s_c {
  align-items: center;
}
.flex_w {
  flex-wrap: wrap;
}
.flex_s_ev {
  justify-content: space-evenly;
}
.vr_img {
  position: relative;
  width: 114px;
  height: 65px;
}
.vr_img_icon {
  position: absolute;
  z-index: 1;
  top: 66px;
  left: 44%;
  width: 50px;
}
.imgSty {
  width: 26px;
  height: 26px;
}
.erji {
  widows: 24px;
  height: 24px;
  margin-right: 8px;
}
.beginBtn {
  background: #06c35f;
  width: 170px;
  padding: 5px 0;
  border-radius: 10px;
  color: #fff;
  margin: 0 auto;
}
.adRightList {
  z-index: 9;
  position: fixed;
  bottom: 85px;
  right: 20px;
  .adRightListImg {
    width: 150px;
    height: 40px;
    border-radius: 15px;
  }
  .adRightListText {
    width: 120px;
    height: 55px;
    font-size: 14px;
    background-color: #fff;
    padding: 5px;
    border-radius: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
